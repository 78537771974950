import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './Footer.scss';
import facebookLogo from '../../../assets/facebook-logo.png';
import facebookLogoG from '../../../assets/facebook-logo-g.png'
import instaLogo from '../../../assets/insta-logo.png'
import instaLogoG from '../../../assets/insta-logo-g.png'
import { Moon } from 'lunarphase-js';
import moonWanningCrescent from "../../../assets/moon/wanning-crescent.svg";
import { useState } from 'react';


export const Footer = ({ isNight }) => {
  const { t } = useTranslation('common');
  
    const [date, setDate] = useState(new Date())
    const [phase, setPhase] = useState(Moon.lunarPhase(date))

  return (

    <div className={isNight ? 'footer-container green cta-border-green' : 'footer-container blue cta-border-blue'} >
      <div style={{ marginTop: '30px' }}>
        {t("footer.rights")}
      </div>
      <div style={{ marginTop: '30px' }}>
        <Link
          to={{
            pathname: "/privacy",
          }}
        >{t('commons.links.privacy')}</Link>
        {/*<Link
                    to={{
                        pathname: "/rules",
                    }}
                >{t("home.rules")}</Link>*/}
      </div>
      <div className='moon-phase-container'>{phase === "Waning Crescent" && <img src={moonWanningCrescent} alt="moon phase" />}</div>
      <div>
        <div className={isNight ? 'green-border ico-container' : 'blue-border ico-container'}>
          <a rel="noreferrer" target='_blank' href='https://www.facebook.com/nextgennightvision' ><img src={isNight ? facebookLogoG : facebookLogo} alt='facebook icon' /></a >
        </div>
        <div className={isNight ? 'green-border ico-container' : 'blue-border ico-container'}>
          <a rel="noreferrer" target='_blank' href='https://www.instagram.com/nnvs_pl/' ><img src={isNight ? instaLogoG : instaLogo} alt='instagram icon' /></a >
        </div>
      </div>
    </div >


  );
};
